import { DiscountCodeProps } from "components/Card/DiscountCode.props"
import { getDiscountCodes } from "~/utils/user/getDiscountCodes"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

export const useDiscountCodes = () => {
  const { t } = useI18n()
  const isLogged = useIsLogged()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)
  const { addNotification } = useNotification()
  const { cart, applyCoupon, applyGiftCard } = useCart()
  const { openModal } = useModal()
  const { updateUserSubscriptionNewsletter, updateNewsletterSubscription } =
    useUserStore()

  const discountCodes = useState<DiscountCodeProps[]>(
    "discount-codes",
    () => []
  )

  const newCouponCandidate = useState<{
    code: string
    indexDiscountCode: number | null
  }>("new-coupon-candidate", () => ({
    code: "",
    indexDiscountCode: null
  }))

  const refreshDiscountCodes = async () =>
    (discountCodes.value = await getDiscountCodes(
      isLogged.value,
      user.value,
      cart.value?.coupon_code
    ))

  const changeStatusDiscountCode = (
    discountCode: string,
    status: DiscountCodeProps["status"]
  ) => {
    couponsManager.data = null
    discountCodes.value = [...discountCodes.value].map((coupon) => {
      if (coupon.discountCode === discountCode) {
        return {
          ...coupon,
          status
        }
      }
      return coupon
    })
  }

  const handleApplyDiscountCode = async (
    indexDiscountCode: number,
    force?: boolean
  ) => {
    couponsManager.data = null
    const discount = discountCodes.value?.find(
      (code, index) => index === indexDiscountCode
    )
    const notificationSuccess: NotificationAlertProps = {
      id: Math.random(),
      type: "success",
      notificationAlertText: t("discounts.codeAppliedSuccessfully"),
      isWithCloseIcon: true
    }
    const notificationFailure: NotificationAlertProps = {
      id: Math.random(),
      type: "warning",
      notificationAlertText: t("discounts.codeAppliedError"),
      isWithCloseIcon: true
    }
    if (discount) {
      switch (discount.ctaFunction) {
        case "applyCode":
          if (
            !!cart.value?.coupon_code &&
            discount?.discountCode !== cart.value?.coupon_code &&
            !force
          ) {
            newCouponCandidate.value.code = discount?.discountCode!
            newCouponCandidate.value.indexDiscountCode = indexDiscountCode
            openModal("change-coupon")
            return
          }
          changeStatusDiscountCode(discount?.discountCode, "applying")
          try {
            discount?.isGiftCard
              ? await applyGiftCard(discount?.discountCode)
              : await applyCoupon(discount?.discountCode)

            /**
             * @description Send envent when user apply coupon
             * */
            const { event, sendEvent } = useGAEvent(
              `track_coupon_use_${discount ?? ""}`,
              "custom_event"
            )
            event.value = {
              event: "coupon_use",
              category: "pagina",
              label: discount.discountCode,
              action: discount.discount + discount.symbol
            } as any

            sendEvent({ clearEcommerce: false })

            addNotification(notificationSuccess)
          } catch {
            addNotification(notificationFailure)
          }

          break
        case "subscribe":
          const { error } = await updateUserSubscriptionNewsletter(
            isLogged.value,
            true
          )
          if (!error.value) {
            //Refresh discount codes
            userStore.optimisticUpdateNewsletterSubscription(true, true)
            await refreshDiscountCodes()
          }
          break
        case "signup":
          navigateToLoginSignupPage()
          break
      }
    }
  }

  watchDebounced(
    user,
    async () => {
      await refreshDiscountCodes()
    },
    { debounce: 600 }
  )

  return {
    discountCodes,
    newCouponCandidate,
    handleApplyDiscountCode,
    refreshDiscountCodes
  }
}
